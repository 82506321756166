var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pc calendario-checkin-manager checkin-calendar"},[(_vm.isPreLoad)?_c('div',{staticClass:"preload"},[_c('div',{staticClass:"preload-wrapper"}),_vm._m(0)]):_vm._e(),_c('header',{staticClass:"p-3"},[_c('div',{staticClass:"row align-items-center"},[_c('div',{staticClass:"col pr-0"},[_c('h1',{},[_vm._v(_vm._s(_vm.translator.calendari))])]),_c('div',{staticClass:"col pl-0"},[_c('div',{staticClass:"navegacion-semanas"},[_c('a',{staticClass:"p-1 arrow",attrs:{"href":"#"},on:{"click":_vm.lastWeek}},[_c('i',{staticClass:"fal fa-angle-left fa-lg"})]),_c('div',{staticClass:"semana mx-3"},[_vm._v(_vm._s(_vm.mostrarFecha))]),_c('a',{staticClass:"p-1 arrow",attrs:{"href":"#"},on:{"click":_vm.nextWeek}},[_c('i',{staticClass:"fal fa-angle-right fa-lg"})])])])])]),_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[_c('div',{key:"3",staticClass:"calendario-checkin-manager"},[_c('div',{staticClass:"calendario-reservas table-responsive"},[_c('table',{staticClass:"table table-bordered"},[_c('thead',[_c('th',{staticClass:"week-days"},[_vm._v(_vm._s(_vm.translator.propietat))]),_vm._l((_vm.ShowDays),function(dia,index){return _c('th',{key:index,staticClass:"week-days",on:{"click":function($event){return _vm.reloadDay(dia)}}},[_vm._v(_vm._s(dia.dia)+" "+_vm._s(_vm._f("convertDayWeek")(_vm.moment(dia.fecha).isoWeekday(),_vm.translator.weekdayShort)))])})],2),_c('tbody',{staticClass:"days-container"},_vm._l((_vm.propiedadesVisibles),function(prop){return _c('tr',{key:prop.rowId},[_c('td',{staticClass:"name"},[_c('span',{staticClass:"limit-text"},[_vm._v(_vm._s(prop.nombrePropiedad))])]),_vm._l((prop.diasPropiedad),function(dia){return _c('td',{key:((dia.fecha) + "-" + (prop.nombrePropiedad)),class:{
                                    'entrada-reserva': dia.estado == 'checkin',
                                    'dia-reservado': dia.estado == 'ocupado',
                                    'salida-reserva': dia.estado == 'checkout',
                                    'salida-entrada': dia.estado == 'inout',
                                    'bloqueado': dia.estado == 'bloqueado',
                                    'salida-bloqueada': dia.estado == 'salida-bloqueada',
                                    'entrada-bloqueada': dia.estado == 'entrada-bloqueada',
                                    'checkout-and-block': dia.estado == 'checkout-and-block',
                                    'block-and-checkin': dia.estado == 'block-and-checkin',
                                  },on:{"click":function($event){return _vm.marcado(dia.fecha, prop.nombrePropiedad, prop.lodgingId)}}},[(dia.estado == 'checkin')?_c('div',{staticClass:"entrada-reserva-i"},[_c('div',{staticClass:"reserva-numero"},[_c('router-link',{attrs:{"to":{ name: 'Booking', params: { bookingId: dia.label }, query: { id: _vm.$route.query.id }}}},[_c('i',{staticClass:"fas fa-info-circle fa-lg"})])],1)]):_vm._e(),(dia.estado == 'checkout')?_c('div',{staticClass:"salida-reserva-i"}):_vm._e(),(dia.estado == 'inout')?_c('div',{staticClass:"bg"},[_c('div',{staticClass:"salida-entrada-1"}),_c('div',{staticClass:"salida-entrada-2"},[_c('div',{staticClass:"contenedor-numero"},[_c('div',{staticClass:"reserva-numero"},[_c('router-link',{attrs:{"to":{ name: 'Booking', params: { bookingId: dia.label }, query: { id: _vm.$route.query.id }}}},[_c('i',{staticClass:"fas fa-info-circle fa-lg"})])],1)])])]):_vm._e()])})],2)}),0)])])])]),_c('NavbarCheckin',{attrs:{"translator":_vm.translator,"user":_vm.user}})],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"preload-dots"},[_c('div',{staticClass:"hollow-dots-spinner"},[_c('div',{staticClass:"dot"}),_c('div',{staticClass:"dot"}),_c('div',{staticClass:"dot"})])])}]

export { render, staticRenderFns }