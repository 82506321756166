<template>
  <div class="pc calendario-checkin-manager checkin-calendar">

    <div v-if="isPreLoad" class="preload">
      <div class="preload-wrapper"></div>
      <div class="preload-dots">
        <div class="hollow-dots-spinner">
          <div class="dot"></div>
          <div class="dot"></div>
          <div class="dot"></div>
        </div>
      </div>  
    </div>

    <header class="p-3">
      <div class="row align-items-center">
        <div class="col pr-0">
          <h1 v-cloak>{{ translator.calendari }}</h1>
        </div>
        <div class="col pl-0">
          <div class="navegacion-semanas">

            <a class="p-1 arrow" href="#" @click="lastWeek">
              <i class="fal fa-angle-left fa-lg"></i>
            </a>
            <div class="semana mx-3">{{ mostrarFecha }}</div>
            <a class="p-1 arrow" href="#" @click="nextWeek">
              <i class="fal fa-angle-right fa-lg"></i>
            </a>
          </div>
        </div>
      </div>
    </header>


    <!-- Calendario Semanal -->
    <transition name="fade" mode="out-in">
        <div key="3" class="calendario-checkin-manager">
            <div class="calendario-reservas table-responsive">

                <table class="table table-bordered">
                    <thead>
                    <th class="week-days">{{ translator.propietat }}</th>
                    <th v-for="(dia, index) in ShowDays" :key="index" @click="reloadDay(dia)" class="week-days">{{ dia.dia }} {{ moment(dia.fecha).isoWeekday() | convertDayWeek(translator.weekdayShort) }}</th>
                    </thead>
                    <tbody class="days-container">
                        <tr v-for="prop in propiedadesVisibles" :key="prop.rowId">
                            <td class="name"><span class="limit-text">{{ prop.nombrePropiedad }}</span></td>
                            <td v-for="dia in prop.diasPropiedad"
                                :key="`${dia.fecha}-${prop.nombrePropiedad}`"
                                @click="marcado(dia.fecha, prop.nombrePropiedad, prop.lodgingId)"
                                :class="{
                                      'entrada-reserva': dia.estado == 'checkin',
                                      'dia-reservado': dia.estado == 'ocupado',
                                      'salida-reserva': dia.estado == 'checkout',
                                      'salida-entrada': dia.estado == 'inout',
                                      'bloqueado': dia.estado == 'bloqueado',
                                      'salida-bloqueada': dia.estado == 'salida-bloqueada',
                                      'entrada-bloqueada': dia.estado == 'entrada-bloqueada',
                                      'checkout-and-block': dia.estado == 'checkout-and-block',
                                      'block-and-checkin': dia.estado == 'block-and-checkin',
                                    }
                                  ">

                                <!-- Checkin -->
                                <div v-if="dia.estado == 'checkin'" class="entrada-reserva-i">
                                    <div class="reserva-numero">
                                        <router-link :to="{ name: 'Booking', params: { bookingId: dia.label }, query: { id: $route.query.id }}">
                                            <i class="fas fa-info-circle fa-lg"></i>
                                        </router-link>
                                    </div>
                                </div>

                                <!-- Checkout -->
                                <div v-if="dia.estado == 'checkout'" class="salida-reserva-i"></div>

                                <!-- Chekin/checkout -->
                                <div v-if="dia.estado == 'inout'" class="bg">
                                    <div class="salida-entrada-1"></div>
                                    <div class="salida-entrada-2">
                                        <div class="contenedor-numero">
                                            <div class="reserva-numero">
                                                <router-link :to="{ name: 'Booking', params: { bookingId: dia.label }, query: { id: $route.query.id }}">
                                                    <i class="fas fa-info-circle fa-lg"></i>
                                                </router-link>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <!-- Bloqueo entrada -->
                                <!-- Bloqueo salida -->
                                <!-- Checkout/bloqueo -->
                                <!-- Bloqueo/checkin -->

                                <!-- <div v-if="dia.estado == 'entrada-bloqueada'" class="entrada-bloqueada-i"></div>

                                <div v-if="dia.estado == 'salida-bloqueada'" class="salida-bloqueada-i"></div>

                                <div v-if="dia.estado == 'checkout-and-block'" class="bg">
                                    <div class="checkout-and-block-1"></div>
                                </div>

                                <div v-if="dia.estado == 'block-and-checkin'" class="bg">
                                    <div class="block-and-checkin-1"></div>
                                    <div class="salida-entrada-2">
                                        <div class="contenedor-numero">
                                            <div class="reserva-numero reserva-numero-block-and-checkin">
                                                <router-link :to="{ name: 'booking', params: { bookingId: dia.label }, query: { id: $route.query.id }}">
                                                    <i class="fas fa-info-circle fa-lg"></i>
                                                </router-link>
                                            </div>
                                        </div>
                                    </div>
                                </div> -->

                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </transition>
    
    <NavbarCheckin :translator="translator" :user="user" />

  </div>
</template>

<script>
import { mapState } from "vuex";
import NavbarCheckin from "@/components/NavbarCheckin.vue";
const $ = require("jquery");

export default {
  name: "Calendar",

  components: {
    NavbarCheckin,
  },

  watch: {
    translator() {
      this.getCalendars();
    },
    calendars() {
      this.getDays() 
      this.isPreLoad = false;
    }
  },

  computed: {
    ...mapState("utils", ["user", "translator"]),
    ...mapState("checkin", ["calendars"]),
  },

  data() {
    return {
      isPreLoad: false,
      calendarRows: {},
      calendarItems: {},
      traductorDias: JSON.parse(sessionStorage.getItem("diccionario")).weekdayShort,
      rowsApartamentos: [],
      reservasApartamentos: [],

      ShowDays: [],
      mostrarFecha: null,
      propiedadesVisibles: [],
      datefromMin: this.moment(new Date()).format('yyyy-MM-DD'),
      datefrom: this.moment(new Date()).format('yyyy-MM-DD'),
      dateto: this.moment(new Date()).add(6, 'd').format('yyyy-MM-DD'),
    };
  },


  async created() {
    this.isPreLoad = true;
    sessionStorage.setItem('listado', 'Calendar');
    try {
      this.translator 
        ? this.getCalendars()
        : await this.$store.dispatch("utils/getUser", { usr: String(this.$route.query.id).replace(/[ ]/g, '+') });
    } catch (error) {
      console.error(error);
    }
  },

  methods: {
    async getCalendars() {
        this.ShowDays = [];
        this.propiedadesVisibles = [];
        this.datefrom = this.moment(this.datefrom).format("yyyy-MM-DD");
        this.dateto = this.moment(this.datefrom).add(6, 'd').format('yyyy-MM-DD');

        await this.$store.dispatch("checkin/getCalendars",
            {
                usr: String(this.$route.query.id).replace(/[ ]/g, "+"),
                datefrom: this.moment(this.datefrom).add(-1, 'd').format("yyyy-MM-DD"), //petición del día antes para saber si es una salida o final de un bloqueo
                dateto: this.dateto,
                country: '',
                region: 0,
                group: 0,
                owner: 0,
                properties: 0,
                management: 0,
                typology: 0,
                capacity: 0,
            }
        );
        this.isPreLoad = false;
    },

    getDays() {
        for (let x = 0; x < 7; x++) {
            let fechaInicial = this.moment(this.datefrom).clone();
            let sumDay = fechaInicial.add(x, "d");
            this.ShowDays.push({ fecha: this.moment(sumDay).format("yyyy-MM-DD"), dia: this.moment(sumDay).format("D") })
        }
        this.showDates();
        this.orderWSData();
    },
    showDates() {
        this.mostrarFecha = `${this.moment(new Date(this.ShowDays[0].fecha)).format("DD-MMM")} - ${this.moment(new Date(this.ShowDays[6].fecha)).format("DD-MMM")}`
    },
    reloadDay(day) {
        if (this.moment(new Date(day.fecha)).format('yyyy-MM-DD') != this.moment(this.datefrom).format('yyyy-MM-DD')) {
            this.datefrom = this.moment(new Date(day.fecha)).format('yyyy-MM-DD');
            this.dateto = this.moment(new Date(day.fecha)).add(6, 'd').format('yyyy-MM-DD');
            this.getCalendars();
        }
    },
    lastWeek() {
        this.datefrom = this.moment(this.datefrom).add(-7, 'd').format('yyyy-MM-DD');
        this.dateto = this.moment(this.datefrom).add(6, 'd').format('yyyy-MM-DD');
        this.getCalendars();
    },
    nextWeek() {
        this.datefrom = this.moment(this.datefrom).add(7, 'd').format('yyyy-MM-DD');
        this.dateto = this.moment(this.datefrom).add(6, 'd').format('yyyy-MM-DD');
        this.getCalendars();
    },
    orderWSData() {
        this.propiedadesVisibles = [];
        let isCheckout = false;
        Object.entries(this.calendars.rows).forEach(row => {
            let isFirstIteration = true;
            isCheckout = false;
            //let isBlock = false;
            let nombrePropiedad = row[1].label
            let rowId = row[1].rowId
            let lodgingId = row[1].lodging_id
            let diasPropiedad = []
            Object.entries(this.calendars.items).forEach(item => {
                if (rowId === item[1].rowId) {
                    let estado = "";
                    let fecha = item[1].date;
                    let id = item[1].id;
                    let time = item[1].time;
                    let label = parseInt(item[1].label);
                    let rowId = item[1].rowId;

                    if (new Date(fecha) >= new Date(time.start) && new Date(fecha) <= new Date(time.end)) {
                        estado = "ocupado";
                    }

                    if (fecha === time.start) {
                        if (isCheckout) {
                            estado = "inout";
                            isCheckout = false;
                        } else {
                            estado = 'checkin';
                            isCheckout = false;
                        }
                    } else {
                        if (isCheckout) {
                            estado = 'checkout';
                            isCheckout = false;
                        }
                    }

                    if (this.moment(fecha).format('yyyy-MM-DD') === this.moment(time.end).add(-1, 'd').format('yyyy-MM-DD') && !isCheckout) {
                        isCheckout = true;
                    }

                    if (label >= 1 && label <= 9) {
                        // if (estado == "checkout") {
                        //     estado = 'checkout-and-block';
                        // } 
                        // else {
                        //     isBlock ? estado = 'bloqueado' : estado = 'entrada-bloqueada';
                        // }

                        // isBlock = true;
                    }

                    if (label == 0 || label >= 10) {
                        // if (isBlock && estado == 'checkin') {
                        //     estado = 'block-and-checkin';
                        // } else {
                        //     isBlock ? estado = 'salida-bloqueada' : null;
                        // }
                        // isBlock = false;
                    }

                    // Si es el primer día lo saltamos, solo se usa para info de posibles salidas o bloqueos
                    isFirstIteration ? isFirstIteration = false : diasPropiedad.push({ fecha, id, time, label, rowId, estado });
                }
            })
            this.propiedadesVisibles.push({ nombrePropiedad, rowId, lodgingId, diasPropiedad });
        })
        //console.log('->', this.propiedadesVisibles);
    },
    marcado(dia = null, propiedad = null, lodgingId = null, typeBookingOrBlock = '') {
        this.selectDay = dia;
        this.modalSelectProperty = lodgingId + "|" + propiedad;
        this.propertyId = lodgingId;
        this.typeBookingOrBlock = typeBookingOrBlock;
        $('#modalCenter').modal('show');
    },
    async saveBookingOrBlock() {
        //modalSelectProperty = id + '|' + name 
        const arrayStr = this.modalSelectProperty.split('|')
        this.propertyId == '' ? this.propertyId = arrayStr[0] : null
        // Reserva
        if (this.typeBookingOrBlock == 'booking') {

            this.error = [];
            this.modalSelectProperty == 'null|null' ? this.error.push(this.translator.faltapropietat) : null;
            this.newBooking.name == '' ? this.error.push(this.translator.faltanom) : null;
            this.selectDay == '' ? this.error.push(this.translator.faltadata) : null;
            this.newBooking.numberNights == '' ? this.error.push(this.translator.faltanumnits) : null;
            this.newBooking.amount == '' || this.newBooking.amount <= 0 ? this.error.push(this.translator.faltaimport) : null;

            if (this.error.length == 0) {

                await this.$store.dispatch("manager/postReservaAfegir", {
                    usr: String(this.$route.query.id).replace(/[ ]/g, '+'), //String
                    nom: this.newBooking.name, //String
                    cognom: this.newBooking.surname, //String
                    telefon: this.newBooking.phone, //String
                    email: this.newBooking.email, //String
                    adults: this.newBooking.adults === '' ? 0 : this.newBooking.adults, //Int
                    joves: this.newBooking.children === '' ? 0 : this.newBooking.children, //Int
                    bebes: this.newBooking.babies === '' ? 0 : this.newBooking.babies, //Int
                    observacions: this.newBooking.remarks, //String
                    lodgingId: arrayStr[0], //Int
                    entrada: this.selectDay, //Date
                    nits: parseInt(this.newBooking.numberNights), //Int
                    preu: this.newBooking.amount == '' ? 0 : this.newBooking.amount //Decimal
                });

                this.typeBookingOrBlock = this.modalSelectProperty = this.selectDay = this.newBooking.name = this.newBooking.surname = this.newBooking.email = this.newBooking.phone = this.newBooking.adults = this.newBooking.children = this.newBooking.babies = this.newBooking.remarks = this.newBooking.amount = this.newBooking.numberNights = '';
                $('#modalCenter').modal('hide');
                setTimeout(() => {
                    this.getCalendars();
                }, 800)
            }
            // Bloqueo
        } else if (this.typeBookingOrBlock == 'block') {
            this.error = [];
            this.modalSelectProperty == 'null|null' ? this.error.push(this.translator.faltapropietat) : null;
            this.newBlock.block == '' ? this.error.push(`${this.translator.bloquejar}/${this.translator.desbloquejar}`) : null;
            this.selectDay == '' ? this.error.push(this.translator.faltadata) : null;
            this.newBlock.numNights == '' ? this.error.push(this.translator.faltanumnits) : null;

            if (this.error.length == 0) {
                console.log(this.propertyId)
                await this.$store.dispatch("manager/postBloquejarCalendari", {
                    usr: String(this.$route.query.id).replace(/[ ]/g, '+'),
                    lodgingId: arrayStr[0],
                    data: this.selectDay,
                    nits: this.newBlock.numNights,
                    bloquejar: this.newBlock.block == 'true' ? true : false,
                });
                this.typeBookingOrBlock = this.newBlock.numNights = this.modalSelectProperty = this.selectDay = this.newBlock.block = '';
                $('#modalCenter').modal('hide');
                setTimeout(() => {
                    this.getCalendars();
                }, 200)
            }
        }
    },
  },
  filters: {
      convertDayWeek(date, days) {
          if (!date) return "";
          date = date == 7 ? days[0] : days[parseInt(date)];
          return date;
      },
  },
};
</script>

<style>
img{
  width: 20px;
  height: 20px;
}
.checkin-calendar .arrow {
  color: #9BA0AB;
}
</style>